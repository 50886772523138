import React, { useCallback, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import {
  CommonHeader,
  FeedItemList,
  Loading,
  LoadingMoreIndicator,
  PullToRefresh,
  ScrollToTopButton,
  ShowTranslation,
} from "../../components";
import getTranslation from "../../common/utils/getTranslation";
import colors from "../../styles/colors";
import { Container, StickyHeader } from "./styles";
import { IFeedItem } from "../../common/types/commonTypes";
import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";
import { LoadingMoreIndicatorWrapper } from "../MainFeed/styles";
import { fetchBookmarkList } from "../../features/bookmark/bookmarkThunks";
import {
  selectBookmarkCurrentPageId,
  selectBookmarkList,
  selectBookmarkLoadingStatus,
  selectBookmarkNextPageId,
} from "../../features/bookmark/bookmarkSlice";
import useScroll from "../../common/hooks/useScroll";
import ReactNativeWebview from "../../common/utils/ReactNativeWebview";
import { delay } from "../../common/utils/globalUtils";

const BOOKMARK = "BOOKMARK";

const BookmarkList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pathname } = location;

  const loading = useAppSelector(selectBookmarkLoadingStatus);
  const bookmarkList = useAppSelector(selectBookmarkList);
  const nextPage = useAppSelector(selectBookmarkNextPageId);
  const currentPage = useAppSelector(selectBookmarkCurrentPageId);

  const { isReachedBottom } = useScroll({
    throttleValue: 100,
  });

  const getBookmarkList = useCallback(
    async (fetchType: "REFRESH" | "MORE" | "INIT") => {
      if (fetchType === "REFRESH" || fetchType === "INIT") {
        await dispatch(fetchBookmarkList({ fetchType, page: 0 }));
      } else {
        await dispatch(fetchBookmarkList({ fetchType, page: nextPage }));
      }
    },
    [dispatch, nextPage]
  );

  const onRefreshPage = async () => {
    await delay(400);

    getBookmarkList("REFRESH");
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const navigateToFeedDetail = (feed: IFeedItem) => {
    ReactNativeWebview.postMessageToApp({
      type: "LOG_DATA_FROM_FEED",
      data: {
        eventName: "Feed_Click_BookmarkList",
        eventParams: {
          content:
            feed.feedpost.content.type === "CALL_ROOM"
              ? "NORMAL"
              : feed.feedpost.content.type,
          feedpost_id: feed.feedpost.feedpost_id,
          target_uuid: feed.user.uuid,
        },
      },
    });

    navigate(`/feed_detail/${feed.feedpost.feedpost_id}`, {
      state: {
        prevpath: pathname,
        access: "BOOKMARK",
      },
    });
  };

  const navigateToFeedReportDetail = (feed: IFeedItem) => {
    navigate(`/feed_report_detail/${feed.feedpost.feedpost_id}`, {
      state: {
        prevpath: pathname,
      },
    });
  };

  useEffect(() => {
    ReactNativeWebview.postMessageToApp({
      type: "LOG_DATA_FROM_FEED",
      data: {
        eventName: "Feed_Hit_BookmarkPage",
      },
    });
  }, []);

  useEffect(() => {
    if (
      isReachedBottom &&
      loading === "NONE" &&
      location.pathname === "/bookmark_list" &&
      currentPage !== nextPage &&
      nextPage !== 0
    ) {
      getBookmarkList("MORE");
    }
  }, [
    isReachedBottom,
    loading,
    location.pathname,
    getBookmarkList,
    currentPage,
    nextPage,
  ]);

  if (loading === "INIT_LOADING") {
    return <Loading />;
  }

  return (
    <Container>
      <StickyHeader>
        <CommonHeader
          headerLeft={"back"}
          headerTitle={
            <ShowTranslation size={17} weight={"m"} color={colors.Gray900}>
              {getTranslation("FeedBookmarkListPage.headerTitle")}
            </ShowTranslation>
          }
          onClickLeft={onClickBack}
        />
      </StickyHeader>

      <LoadingMoreIndicatorWrapper>
        <LoadingMoreIndicator isLoadingMore={loading === "LOADING_MORE"} />
      </LoadingMoreIndicatorWrapper>

      <PullToRefresh onRefresh={onRefreshPage}>
        <FeedItemList
          listType={BOOKMARK}
          data={bookmarkList}
          subtractHeight={48}
          emptyText={getTranslation("FeedBookmarkListPage.emptyList")}
          emptyBackgroundColor={"gray"}
          navigateToFeedDetail={navigateToFeedDetail}
          navigateToFeedReportDetail={navigateToFeedReportDetail}
        />
      </PullToRefresh>

      <ScrollToTopButton />
    </Container>
  );
};

export default BookmarkList;
