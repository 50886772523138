const colors = {
  //gray
  Black: "#000000",
  Gray900: "#222222",
  Gray800: "#333333",
  Gray700: "#666666",
  Gray600: "#858585",
  Gray500: "#AAAAAA",
  Gray400: "#CCCCCC",
  Gray300: "#E6E6E6",
  Gray200: "#F2F2F2",
  Gray100: "#F8F8F8",
  White: "#FFFFFF",
  //blue
  Blue900: "#0E2062",
  Blue800: "#062BB0",
  Blue700: "#3D67FC",
  Blue500: "#85A7FF",
  Blue300: "#DDE9FF",
  //purple
  Purple900: "#250677",
  Purple750: "#6733ED",
  Purple700: "#723DFC",
  Purple500: "#A786FF",
  Purple300: "#E3D2FF",
  //mint
  Mint800: "#077878",
  Mint700: "#00C2B0",
  MintNeon: "#0FF6CD",
  //navy
  Navy950: "#050C18",
  Navy900: "#050E24",
  Navy800: "#0C1A3B",
  Navy750: "#16264E",
  Navy700: "#273559",
  Navy650: "#2F3D66",
  Navy600: "#394A77",
  //red
  Red100: "#FFEAEC",
  Red700: "#EE434E",
  RedLight: "#FF7156",
  //yellow
  Yellow700: "#FFCD11",
  Yellow300: "#FFE687",
  //orange
  Orange100: "#FFECD0",
  Orange700: "#FFA011",
  Orange900: "#FF7A00",
  //hotpink
  Hotpink700: "#F74379",

  //NOTE - 디자인 시스템 이전 기존에 사용 중인 피드 색상
  PRIMARY: "rgb(18, 196, 174)",
  neonGreen: "rgb(0, 219, 199)",
  CNTMint: "rgb(0, 194, 176)",
  CNTDarkGreen: "rgb(0, 156, 142)",
  CNTMidGreen: "rgb(214, 248, 242)",
  CNTLightGreen: "rgb(238, 251, 250)",
  CNTPurple: "rgb(108, 77, 255)",
  lightPurple: "rgb(241, 239, 255)",
  callingGreen: "rgb(52, 199, 89)",
  notiRed: "rgb(255, 86, 53)",
  bannerBlue: "rgb(57, 108, 233)",
  toastGray: "rgb(238, 239, 241)",
  linkBlue: "rgb(37, 129, 192)",
};

export default colors;
