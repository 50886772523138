import styled from "styled-components";
import colors from "../../styles/colors";
import fontWeight from "../../styles/fontWeights";

const Container = styled.div`
  background: rgb(242, 242, 242);
  border: 1px solid rgba(230, 230, 230, 0.5);
  padding: 12px 16px;
  border-radius: 6px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 16px;
`;

const InnerText = styled.div`
  font-size: 15px;
  color: ${colors.Gray700};
  font-weight: ${fontWeight.medium};
  line-height: 20px;
`;

const LinkText = styled.span`
  color: ${colors.linkBlue};
  font-weight: ${fontWeight.medium};
`;

export { Container, InnerText, LinkText };
