import React, { forwardRef, useState, useEffect, ChangeEvent } from "react";
import { debounce } from "lodash";
import {
  Container,
  TextInput,
  TextInputContainer,
  ImageButton,
  InputUploadContainer,
  GifAudioButtonContainer,
  GifWatermark,
  GifWaterMarkCloseContainer,
  Gif,
  FilesContainer,
  MentionedUserContainer,
} from "./styles";

import colors from "../../styles/colors";

import getTranslation from "../../common/utils/getTranslation";
import generateRandomNickname from "../../common/utils/generateRandomNickname";

import uploadIcon from "../../assets/images/img_upcircle_filled@3x.png";
import disabledUploadIcon from "../../assets/images/img_upcircle_filled_02@3x.png";
import disabledAudio from "../../assets/images/btn20RecordLightgray@3x.png";
import audio from "../../assets/images/btn20RecordGray900@3x.png";
import close from "../../assets/images/btnCancelCircle18@3x.png";
import close2 from "../../assets/images/Close_10_Gray800@3x.png";

import { SoundPlayer, ShowTranslation } from "../../components";

import {
  IFeedCommentItem,
  IFeedRecommentItem,
} from "../../common/types/commonTypes";

interface IProps {
  text: string;
  files: Array<{ url: string }>;
  audioDuration: number;
  fileType: "GIF" | "AUDIO" | "NORMAL" | "IMAGE";
  isUploadButtonDisabled: boolean;
  selectedCommentToReply: IFeedCommentItem | null;
  selectedRecommentToReply: IFeedRecommentItem | null;
  onClickUpload: (fileType: "GIF" | "AUDIO" | "NORMAL" | "IMAGE") => void;
  onChangeText: (text: string) => void;
  onClickRemoveFile: () => void;
  onClickWrite: () => void;
  onClickRemoveMentioningUser: () => void;
  attachCallRoom: any;
}

const CommentInput = (
  {
    text,
    files,
    fileType,
    audioDuration,
    isUploadButtonDisabled,
    selectedCommentToReply,
    selectedRecommentToReply,
    onClickUpload,
    onChangeText,
    onClickRemoveFile,
    onClickWrite,
    onClickRemoveMentioningUser,
    attachCallRoom,
  }: IProps,
  ref: any
) => {
  const [fileSelectorDisabled, setFileSelectorDisabled] = useState(false);

  const onClickWriteButton = debounce(() => {
    onClickWrite();
  }, 300);

  const onChangeInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeText(event.target.value);
  };

  useEffect(() => {
    if (files.length > 0 || attachCallRoom) {
      setFileSelectorDisabled(true);
    } else {
      setFileSelectorDisabled(false);
    }
  }, [files, attachCallRoom]);

  const renderSoundPlayer = () => {
    if (fileType === "AUDIO" && files.length > 0) {
      return (
        <FilesContainer>
          <SoundPlayer
            type={"MINI"}
            audioUrl={files[0].url}
            initDuration={audioDuration}
          />
          <ImageButton
            onClick={onClickRemoveFile}
            width={18}
            height={18}
            marginLeft={8}
            source={close}
          />
        </FilesContainer>
      );
    }
  };

  const renderGif = () => {
    if (fileType === "GIF" && files.length > 0) {
      return (
        <FilesContainer>
          <Gif src={files[0].url} />
          <GifWaterMarkCloseContainer>
            <ImageButton
              onClick={onClickRemoveFile}
              width={18}
              height={18}
              source={close}
            />
            <GifWatermark />
          </GifWaterMarkCloseContainer>
        </FilesContainer>
      );
    }
  };

  const renderMentionedUser = () => {
    let mentioningUserNickname;

    const getMentioningUserNickname = (user: any, item: any) => {
      const randomNickname = generateRandomNickname(
        user.nickname,
        item.feedpost_id
      );

      if (user.is_manager) {
        return user.nickname;
      }

      return randomNickname.length > 20
        ? `${randomNickname.substring(0, 17)}...`
        : randomNickname;
    };

    if (!selectedCommentToReply && !selectedRecommentToReply) {
      return null;
    }

    if (selectedCommentToReply) {
      mentioningUserNickname = getMentioningUserNickname(
        selectedCommentToReply.user,
        selectedCommentToReply.comment
      );
    }

    if (selectedRecommentToReply) {
      mentioningUserNickname = getMentioningUserNickname(
        selectedRecommentToReply.user,
        selectedRecommentToReply.recomment
      );
    }

    return (
      <MentionedUserContainer>
        <ShowTranslation
          highlightWeight={"m"}
          size={13}
          weight={"r"}
          color={colors.Gray500}
        >
          {getTranslation("CommentInput.replyingTo", {
            "{s}": mentioningUserNickname,
          })}
        </ShowTranslation>

        <ImageButton
          onClick={onClickRemoveMentioningUser}
          width={10}
          height={10}
          source={close2}
        />
      </MentionedUserContainer>
    );
  };

  return (
    <Container>
      {renderMentionedUser()}
      {renderSoundPlayer()}
      {renderGif()}

      <InputUploadContainer>
        <TextInputContainer>
          <TextInput
            ref={ref}
            onChange={onChangeInput}
            value={text}
            placeholder={getTranslation("CommentInput.placeHolder") as string}
          />
          <GifAudioButtonContainer>
            <ImageButton
              width={20}
              height={20}
              source={fileSelectorDisabled ? disabledAudio : audio}
              onClick={() => onClickUpload("AUDIO")}
              disabled={fileSelectorDisabled}
            />
          </GifAudioButtonContainer>
        </TextInputContainer>
        <ImageButton
          width={36}
          height={36}
          onClick={onClickWriteButton}
          disabled={isUploadButtonDisabled}
          source={isUploadButtonDisabled ? disabledUploadIcon : uploadIcon}
        />
      </InputUploadContainer>
    </Container>
  );
};

export default forwardRef(CommentInput);
