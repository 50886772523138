import Modal from "styled-react-modal";
import styled from "styled-components";
import colors from "../../styles/colors";

export const ModalBackground = styled.div`
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: 1000;
`;

export const StyledModal = Modal.styled`
  background-color: ${colors.White};
  position: relative;
  flex-direction: column;
  overflow: scroll;
  display: flex;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  padding-bottom: 52px;
`;

export const StickyHeader = styled.div`
  position: sticky;
  top: 0px;
  z-index: 1000;
  background-color: ${colors.White};
`;

export const Container = styled.div``;

export const BannerContainer = styled.div`
  padding: 14px 20px 14px 20px;
  background-color: ${colors.Gray700};
`;

export const TotalCountContainer = styled.div`
  padding: 24px 20px 10px 20px;
`;
export const ListContainer = styled.div`
  padding: 0 20px 0 20px;
`;

export const ItemContainer = styled.div`
  padding: 20px 0 19px 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.Gray300};
`;

export const ReportType = styled.div``;

export const ReportTime = styled.div`
  padding-top: 6px;
`;

export const ShowMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const ShowMoreIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-left: 3px;
`;
