import styled from "styled-components";
import colors from "../../styles/colors";

import {
  ImageSourceButton,
  ImageSourceButtonProps,
} from "../../styles/commonStyles";

interface ImageButtonProps extends ImageSourceButtonProps {
  marginRight?: number;
}

interface IconProps {
  width?: number;
  height?: number;
  marginRight?: number;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.Gray200};
`;

export const FeedCommentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 16px 20px;
  box-sizing: border-box;
`;

export const Icon = styled.img`
  width: ${(props: IconProps) => props.width || 12}px;
  height: ${(props: IconProps) => props.height || 12}px;
  margin-right: ${(props: IconProps) => props.marginRight || 0}px;
`;

export const ImageButton = styled(ImageSourceButton)`
  margin-right: ${(props: ImageButtonProps) => props.marginRight || 0}px;
`;

export const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 6px;
  width: 100%;
`;

export const NicknameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const EmptyMore = styled.div`
  width: 19px;
  height: 19px;
`;

export const ButtonWrapper = styled.div``;

export const ContentWrapper = styled.div`
  margin-top: 6px;
`;

export const LegacyFeedCallRoomWrapper = styled.div`
  border-radius: 8px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.Gray100};
  padding: 20px 0px 20px 0px;
`;

export const ContentContainer = styled.div``;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  position: relative;
`;

export const DashBorder = styled.div`
  width: 22px;
  height: 1px;
  background-color: ${colors.Gray400};
  margin-right: 8px;
`;

export const SeeRecommentsWrapper = styled(ActionButtonsWrapper)`
  height: 14px;
  align-items: center;
  margin-top: 28px;
`;

export const SeeMoreRecommentsWrapper = styled(ActionButtonsWrapper)`
  padding-left: 58px;
  padding-bottom: 12px;
`;

export const IsDeletedWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
