import React, { useState, useEffect, MouseEvent } from "react";
import { debounce } from "lodash";

import { useAppDispatch } from "../../common/hooks/reduxHooks";

import colors from "../../styles/colors";

import useAuth from "../../common/hooks/useAuth";

import generateRandomNickname from "../../common/utils/generateRandomNickname";
import { getCreatedAtTimes } from "../../common/utils/timeFormatters";
import { unwrapResult } from "@reduxjs/toolkit";

import {
  SoundPlayer,
  FeedHiddenContent,
  ShowTranslation,
  BigGif,
  FeedRecommentItem,
} from "../../components";

import more from "../../assets/images/More_18_Gray800@3x.png";
import filledHeart from "../../assets/images/icHeartFill@3x.png";
import emptyHeart from "../../assets/images/icHeartEmpty@3x.png";
import managerCheck from "../../assets/images/icVerified@3x.png";
import male from "../../assets/images/img_feedmale@3x.png";
import female from "../../assets/images/img_feedfemale@3x.png";
import nonbinary from "../../assets/images/img_feednonbinary@3x.png";

import {
  Container,
  FeedCommentContainer,
  ImageButton,
  CommentContainer,
  ButtonWrapper,
  NicknameWrapper,
  Icon,
  ContentWrapper,
  LegacyFeedCallRoomWrapper,
  ActionButtonsWrapper,
  SeeMoreRecommentsWrapper,
  SeeRecommentsWrapper,
  DashBorder,
  EmptyMore,
  IsDeletedWrapper,
} from "./styles";

import {
  createFeedRecomment,
  fetchFeedRecomments,
  likeComment,
  unlikeComment,
} from "../../features/feedDetail/feedDetailThunks";

import {
  IFeedCommentItem,
  IFeedRecommentItem,
} from "../../common/types/commonTypes";

import getTranslation from "../../common/utils/getTranslation";

import ReactNativeWebview from "../../common/utils/ReactNativeWebview";
import { parseNextPageUrl } from "../../common/utils/parseNextPageUrl";

import useUserControl from "../../common/hooks/useUserControl";

type FileType = "AUDIO" | "GIF" | "NORMAL" | "IMAGE";
type Files = Array<{ url: string }>;
type ReplyToParams = {
  feedId: string;
  commentId: string;
  mentionedUserUuid: string;
  text: string;
  fileType: FileType;
  files: Files;
  audioDuration: number;
  callRoomId?: string;
};

interface IProps {
  item: IFeedCommentItem;
  onClickReplyToRecomment: ({
    recomment,
    callback,
  }: {
    recomment: IFeedRecommentItem;
    comment: IFeedCommentItem;
    callback: (params: ReplyToParams) => Promise<void>;
  }) => void;
  onClickReplyToComment: ({
    comment,
    callback,
  }: {
    comment: IFeedCommentItem;
    callback: (params: ReplyToParams) => Promise<void>;
  }) => void;
  onClickLikeComment: (comment: IFeedCommentItem) => void;
  onClickUnlikeComment: (comment: IFeedCommentItem) => void;
  refreshCount: number;
  showUserControlModal: () => void;
}

const FeedCommentItem = ({
  item,
  onClickReplyToComment,
  onClickReplyToRecomment,
  onClickLikeComment,
  onClickUnlikeComment,
  refreshCount,
  showUserControlModal,
}: IProps) => {
  const dispatch = useAppDispatch();
  const { getUserControlType } = useUserControl();
  const { userData } = useAuth();
  const { user, comment, is_liked } = item;

  const {
    uuid,
    sex,
    representative_image,
    relation_with_me,
    is_manager,
    is_delete,
    nickname,
    approval,
    country,
  } = user;

  const {
    comment_id,
    hide,
    content,
    total_count_likes,
    total_count_reports,
    total_count_comments,
    created_at,
    feedpost_id,
    is_delete: comment_is_delete,
  } = comment;

  const { text, url, type, duration } = content;

  const [recomments, setRecomments] = useState<Array<IFeedRecommentItem>>([]);

  const [loading, setLoading] = useState<"LOADING" | "LOADING_MORE" | "NONE">(
    "NONE"
  );

  const [recommentsNextPageUrl, setRecommentsNextPageUrl] =
    useState<string>("");
  const [isRecommentsHidden, setIsRecommentsHidden] = useState<boolean>(true);
  const [isInitRecommentFetched, setIsInitRecommentFetched] =
    useState<boolean>(false);

  useEffect(() => {
    setRecomments([]);
    setRecommentsNextPageUrl("");
    setIsRecommentsHidden(true);
    setIsInitRecommentFetched(false);
  }, [refreshCount]);

  const updateList = (
    recommentList: Array<IFeedRecommentItem>,
    recommentItem: IFeedRecommentItem,
    actionType: "LIKE" | "UNLIKE"
  ) => {
    return recommentList.map((item) => {
      if (
        item.recomment.recomment_id === recommentItem.recomment.recomment_id
      ) {
        const updatedRecomment = { ...recommentItem };
        const { recomment } = updatedRecomment;
        const updatedReommentPost = { ...recomment };

        if (actionType === "LIKE") {
          updatedRecomment.is_liked = true;
          updatedReommentPost.total_count_likes += 1;
          updatedRecomment.recomment = updatedReommentPost;
        }

        if (actionType === "UNLIKE") {
          updatedRecomment.is_liked = false;
          updatedReommentPost.total_count_likes -= 1;
          updatedRecomment.recomment = updatedReommentPost;
        }

        return updatedRecomment;
      }
      return item;
    });
  };

  const getFeedRecomments = async () => {
    try {
      const recommentsResults = await dispatch(
        fetchFeedRecomments({
          commentId: comment_id,
          page: parseNextPageUrl(recommentsNextPageUrl),
        })
      );

      const unwrappedRecommentsResults = unwrapResult(recommentsResults);

      setRecomments((prevRecomments) =>
        prevRecomments.concat(unwrappedRecommentsResults.recomments)
      );
      setRecommentsNextPageUrl(unwrappedRecommentsResults.next_page);
      setLoading("NONE");
      return;
    } catch (error) {}
  };

  const replyTo = async ({
    feedId,
    commentId,
    mentionedUserUuid,
    text,
    fileType,
    files,
    audioDuration,
    callRoomId,
  }: ReplyToParams) => {
    try {
      const replyToResults = await dispatch(
        createFeedRecomment({
          feedId,
          commentId,
          mentionedUserUuid,
          text,
          fileType,
          files,
          audioDuration,
          callRoomId,
        })
      );

      const unwrappedReplyToResults = unwrapResult(replyToResults);

      if (isInitRecommentFetched) {
        setRecomments((prevRecomments) => {
          const newReply = new Array(unwrappedReplyToResults);
          return newReply.concat(prevRecomments);
        });

        setIsRecommentsHidden(false);
        return;
      }
      await getFeedRecomments();

      setIsInitRecommentFetched(true);
      setIsRecommentsHidden(false);
      return;
    } catch (error) {
      if (error === "BLACK_TYPE_USER") {
        ReactNativeWebview.postMessageToApp({
          type: "SHOW_ACCOUNT_SUSPENDED_MODAL",
        });
        return;
      }
      return;
    }
  };

  const onClickMore = (event: MouseEvent<HTMLButtonElement>) => {
    if (getUserControlType() === "PAUSE") {
      // TODO: 유저컨트롤 모달 전역 상태 로 관리 할수있게 refactoring 해야함
      showUserControlModal && showUserControlModal();
      event.stopPropagation();
      return;
    }

    ReactNativeWebview.postMessageToApp({
      type: "SHOW_ACTION_SHEET",
      data: {
        contentUserUuid: uuid,
        contentUserCountry: country,
        contentUserApprovalStatus: approval,
        feedId: feedpost_id,
        commentId: comment_id,
        postType: "COMMENT",
        contentId: comment_id,
        blockPostType: "COMMENT",
      },
    });
    event.stopPropagation();
  };

  const onClickuserInfo = (
    event: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLDivElement>
  ) => {
    if (relation_with_me === "ME" || is_manager) return;

    ReactNativeWebview.postMessageToApp({
      type: "SHOW_PROFILE_EXCHANGE_REQUEST_MODAL",
      data: {
        userUuid: uuid,
        userNickname: nickname,
        userSex: sex,
        feedId: feedpost_id,
        commentId: comment_id,
        contentType: content.type,
        contentText: content.text,
        approvalStatus: approval,
      },
    });

    event.stopPropagation();
  };

  const onClickLikeRecomment = (recomment: IFeedRecommentItem) => {
    dispatch(
      likeComment({
        feedId: recomment.recomment.feedpost_id,
        commentId: recomment.recomment.recomment_id,
      })
    );
    setRecomments((prevRecomments) =>
      updateList(prevRecomments, recomment, "LIKE")
    );

    ReactNativeWebview.postMessageToApp({ type: "LOG_SENT_FEED_LIKES" });
  };

  const onClickUnlikeRecomment = (recomment: IFeedRecommentItem) => {
    dispatch(
      unlikeComment({
        feedId: recomment.recomment.feedpost_id,
        commentId: recomment.recomment.recomment_id,
      })
    );

    setRecomments((prevRecomments) =>
      updateList(prevRecomments, recomment, "UNLIKE")
    );
  };

  const onClickReply = debounce(() => {
    onClickReplyToComment({ comment: item, callback: replyTo });
  }, 300);

  const onClickSeeMoreRecomments = debounce(() => {
    if (loading === "NONE") {
      setLoading("LOADING_MORE");
      getFeedRecomments();
    }
  }, 300);

  const onClickSeeRecomments = debounce(async () => {
    if (!isInitRecommentFetched && loading === "NONE") {
      await getFeedRecomments();
      setIsInitRecommentFetched(true);
    }
    setIsRecommentsHidden(!isRecommentsHidden);
  }, 300);

  const onClickHeart = debounce((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (is_liked) {
      onClickUnlikeComment(item);
      return;
    }
    onClickLikeComment(item);
  }, 300);

  const renderUserIcon = () => {
    const userImage = is_manager
      ? representative_image
      : sex === "F"
      ? female
      : sex === "O"
      ? nonbinary
      : male;
    return (
      <ButtonWrapper>
        <ImageButton
          style={{ borderRadius: 32 / 2 }}
          disabled={is_manager || relation_with_me === "ME" || hide !== "NONE"}
          source={userImage}
          width={32}
          height={32}
          onClick={onClickuserInfo}
        />
      </ButtonWrapper>
    );
  };

  const renderContent = () => {
    const renderSoundPlayer = () => {
      if (type === "AUDIO" && url.length > 0 && "url" in url[0]) {
        return (
          <ContentWrapper>
            <SoundPlayer
              type={"MINI"}
              audioUrl={url[0].url!}
              initDuration={duration}
            />
          </ContentWrapper>
        );
      }
    };

    const renderGif = () => {
      if (type === "GIF" && url.length > 0) {
        return (
          <ContentWrapper>
            <BigGif source={url[0].url!} />
          </ContentWrapper>
        );
      }
    };

    const renderLegacyCallRoom = () => {
      if (type === "CALL_ROOM") {
        return (
          <LegacyFeedCallRoomWrapper>
            <ShowTranslation
              style={{ flexShrink: 1 }}
              size={13}
              color={colors.Gray500}
              weight={"r"}
            >
              {getTranslation("Common.legacyFeature")}
            </ShowTranslation>
          </LegacyFeedCallRoomWrapper>
        );
      }
    };

    const renderHiddenContent = () => {
      if (hide === "CS") {
        return (
          <FeedHiddenContent
            style={{ marginTop: 6, marginLeft: 0, marginRight: 0 }}
            hiddenType={hide}
          />
        );
      }

      if (hide === "REPORT") {
        return (
          <FeedHiddenContent
            style={{ marginTop: 6, marginLeft: 0, marginRight: 0 }}
            hiddenType={hide}
            reportCount={total_count_reports}
          />
        );
      }
    };

    const renderText = () => {
      if (text.length > 0) {
        return (
          <ContentWrapper>
            <ShowTranslation
              style={{
                marginBottom: 6,
                lineHeight: "20px",
                lineBreak: "anywhere",
              }}
              size={15}
              weight={"r"}
              color={colors.Gray900}
            >
              {text}
            </ShowTranslation>
          </ContentWrapper>
        );
      }
    };

    const renderCommentActionButtons = () => {
      return (
        <ActionButtonsWrapper>
          <ShowTranslation
            style={{ marginRight: 12 }}
            size={12}
            weight={"r"}
            color={colors.Gray600}
          >
            {getCreatedAtTimes(created_at)}
          </ShowTranslation>

          <ImageButton
            width={16}
            height={16}
            marginRight={4}
            source={is_liked ? filledHeart : emptyHeart}
            onClick={onClickHeart}
          />

          <ShowTranslation
            style={{ marginRight: 12 }}
            size={13}
            weight={"r"}
            color={colors.Gray600}
          >
            {`${total_count_likes}`}
          </ShowTranslation>

          <ButtonWrapper onClick={onClickReply}>
            <ShowTranslation size={12} weight={"m"} color={colors.Gray600}>
              {getTranslation("FeedCommon.replyToComment")}
            </ShowTranslation>
          </ButtonWrapper>
        </ActionButtonsWrapper>
      );
    };

    const renderSeeAllRecomments = () => {
      if (total_count_comments > 0 || recomments.length > 0) {
        return (
          <SeeRecommentsWrapper onClick={onClickSeeRecomments}>
            <DashBorder />
            <ShowTranslation size={12} weight={"m"} color={colors.Gray700}>
              {isRecommentsHidden
                ? getTranslation("FeedCommon.seeAllReply", {
                    "{n}": total_count_comments.toString(),
                  })
                : getTranslation("FeedCommon.hideAllReply", {
                    "{n}": total_count_comments.toString(),
                  })}
            </ShowTranslation>
          </SeeRecommentsWrapper>
        );
      }
    };

    return (
      <CommentContainer>
        <NicknameWrapper onClick={onClickuserInfo}>
          <ShowTranslation size={14} weight={"m"} color={colors.Gray900}>
            {is_manager
              ? nickname
              : generateRandomNickname(nickname, feedpost_id)}
          </ShowTranslation>

          {is_manager && (
            <Icon
              style={{ marginLeft: 3 }}
              width={12}
              height={12}
              src={managerCheck}
            />
          )}
          {relation_with_me === "ME" && (
            <ShowTranslation
              style={{
                paddingRight: 6,
                paddingLeft: 6,
                height: 16,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: colors.Blue700,
                borderRadius: 8,
                marginLeft: 1,
              }}
              size={11}
              weight={"b"}
              color={colors.White}
            >
              {getTranslation("FeedCommon.myComment")}
            </ShowTranslation>
          )}
        </NicknameWrapper>
        {hide === "NONE" || (hide === "OWN" && uuid === userData.uuid) ? (
          <>
            {renderText()}
            {renderSoundPlayer()}
            {renderGif()}
            {renderLegacyCallRoom()}
            {renderCommentActionButtons()}
            {renderSeeAllRecomments()}
          </>
        ) : (
          renderHiddenContent()
        )}
      </CommentContainer>
    );
  };

  const renderMoreButton = () => {
    if (
      (hide !== "OWN" && hide !== "NONE") ||
      is_manager ||
      relation_with_me === "ME"
    ) {
      return <EmptyMore />;
    }

    return (
      <ButtonWrapper>
        <ImageButton
          source={more}
          width={18}
          height={18}
          onClick={onClickMore}
        />
      </ButtonWrapper>
    );
  };

  const renderRecomments = () => {
    if (isRecommentsHidden || recomments.length === 0) {
      return null;
    }

    return recomments.map((recommentItem, index) => {
      return (
        <FeedRecommentItem
          showUserControlModal={showUserControlModal}
          key={`feed-recomment-id-${recommentItem.recomment.recomment_id}`}
          onClickReplyToRecomment={onClickReplyToRecomment}
          onClickLikeRecomment={onClickLikeRecomment}
          onClickUnlikeRecomment={onClickUnlikeRecomment}
          replyTo={replyTo}
          item={recommentItem}
          parentItem={item}
        />
      );
    });
  };

  const renderSeeMoreRecomments = () => {
    if (
      !isRecommentsHidden &&
      recomments.length > 0 &&
      parseNextPageUrl(recommentsNextPageUrl) > 0
    ) {
      return (
        <SeeMoreRecommentsWrapper onClick={onClickSeeMoreRecomments}>
          <DashBorder />
          <ShowTranslation size={12} weight={"m"} color={colors.Gray700}>
            {getTranslation("FeedCommon.seeMoreReply")}
          </ShowTranslation>
        </SeeMoreRecommentsWrapper>
      );
    }
  };

  const renderIsDeletedUser = () => {
    const renderSeeAllRecomments = () => {
      if (total_count_comments > 0 || recomments.length > 0) {
        return (
          <SeeRecommentsWrapper onClick={onClickSeeRecomments}>
            <DashBorder />
            <ShowTranslation size={12} weight={"m"} color={colors.Gray700}>
              {isRecommentsHidden
                ? getTranslation("FeedCommon.seeAllReply", {
                    "{n}": total_count_comments.toString(),
                  })
                : getTranslation("FeedCommon.hideAllReply", {
                    "{n}": total_count_comments.toString(),
                  })}
            </ShowTranslation>
          </SeeRecommentsWrapper>
        );
      }
    };
    return (
      <IsDeletedWrapper>
        <ShowTranslation
          weight={"r"}
          size={15}
          color={colors.Gray900}
          style={{ marginBottom: "6px", lineHeight: "20px" }}
        >
          {getTranslation("FeedCommon.isDeletedUser")}
        </ShowTranslation>
        <ShowTranslation weight={"r"} size={13} color={colors.Gray500}>
          {getCreatedAtTimes(created_at)}
        </ShowTranslation>
        <CommentContainer style={{ paddingLeft: "32px" }}>
          {renderSeeAllRecomments()}
        </CommentContainer>
      </IsDeletedWrapper>
    );
  };

  const renderIsDeletedComment = () => {
    const renderSeeAllRecomments = () => {
      if (total_count_comments > 0 || recomments.length > 0) {
        return (
          <SeeRecommentsWrapper onClick={onClickSeeRecomments}>
            <DashBorder />
            <ShowTranslation size={12} weight={"m"} color={colors.Gray700}>
              {isRecommentsHidden
                ? getTranslation("FeedCommon.seeAllReply", {
                    "{n}": total_count_comments.toString(),
                  })
                : getTranslation("FeedCommon.hideAllReply", {
                    "{n}": total_count_comments.toString(),
                  })}
            </ShowTranslation>
          </SeeRecommentsWrapper>
        );
      }
    };
    return (
      <IsDeletedWrapper>
        <ShowTranslation
          weight={"r"}
          size={15}
          color={colors.Gray900}
          style={{ marginBottom: "6px", lineHeight: "20px" }}
        >
          {getTranslation("FeedCommon.isDeletedComment")}
        </ShowTranslation>
        <ShowTranslation weight={"r"} size={13} color={colors.Gray500}>
          {getCreatedAtTimes(created_at)}
        </ShowTranslation>
        <CommentContainer style={{ paddingLeft: "32px" }}>
          {renderSeeAllRecomments()}
        </CommentContainer>
      </IsDeletedWrapper>
    );
  };

  if (hide === "OWN" && uuid !== userData.uuid) {
    return null;
  }

  return (
    <Container>
      <FeedCommentContainer>
        {is_delete && renderIsDeletedUser()}
        {!is_delete && comment_is_delete && renderIsDeletedComment()}
        {!comment_is_delete && !is_delete && (
          <>
            {renderUserIcon()}
            {renderContent()}
            {renderMoreButton()}
          </>
        )}
      </FeedCommentContainer>
      {renderRecomments()}
      {renderSeeMoreRecomments()}
    </Container>
  );
};

export default FeedCommentItem;
