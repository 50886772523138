import styled from "styled-components";
import colors from "../../styles/colors";

import {
  ImageSourceButton,
  ImageSourceButtonProps,
} from "../../styles/commonStyles";

interface ImageButtonProps extends ImageSourceButtonProps {
  marginRight?: number;
}

interface IconProps {
  width?: number;
  height?: number;
  marginRight?: number;
}

export const FeedRecommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 58px;
  box-sizing: border-box;
`;

export const LegacyFeedCallRoomWrapper = styled.div`
  border-radius: 8px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.Gray100};
  padding: 20px 0px 20px 0px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Icon = styled.img`
  width: ${(props: IconProps) => props.width || 12}px;
  height: ${(props: IconProps) => props.height || 12}px;
  margin-right: ${(props: IconProps) => props.marginRight || 0}px;
`;

export const ImageButton = styled(ImageSourceButton)`
  margin-right: ${(props: ImageButtonProps) => props.marginRight || 0}px;
`;

export const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 6px;
  width: 100%;
`;

export const NicknameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ButtonWrapper = styled.div``;

export const ContentWrapper = styled.div`
  margin-top: 6px;
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
  position: relative;
  padding-left: 34px;
`;

export const HeartWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 24px;
`;

export const LikeTabWrapper = styled.div``;

export const EmptyMore = styled.div`
  width: 19px;
  height: 19px;
`;

export const IsDeletedWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
