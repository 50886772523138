import styled from "styled-components";
import colors from "../../styles/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.White};
  height: calc(
    100vh -
      ${(props: { subtractHeight?: number }) => props.subtractHeight || 0}px
  );
  align-items: center;
  padding: 0px 30px;
  justify-content: center;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;
