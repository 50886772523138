import React from "react";

import { Container, ErrorIcon } from "./styles";
import { ShowTranslation } from "../../components";
import colors from "../../styles/colors";

interface IProps {
  text: string;
}

const Error = ({ text }: IProps) => {
  return (
    <Container>
      <ErrorIcon />
      <ShowTranslation weight={"r"} size={12} color={colors.Gray500}>
        {text}
      </ShowTranslation>
    </Container>
  );
};

Error.defaultProps = {
  text: "ERROR 404",
};

export default Error;
