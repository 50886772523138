import styled from "styled-components";

import paperForWhite from "../../assets/images/imgPaper60@3x.png";
import paperForGray from "../../assets/images/Paper03_60@3x.png";
import colors from "../../styles/colors";

interface Props {
  backgroundType?: "white" | "gray";
  subtractHeight?: number;
}

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  background-color: ${({ backgroundType }) =>
    backgroundType === "white" ? colors.White : colors.Gray200};
  height: calc(100vh - ${({ subtractHeight }) => subtractHeight || 0}px);
  align-items: center;
  justify-content: center;
`;

export const PaperIcon = styled.img.attrs(
  ({ src }: { src?: Props["backgroundType"] }) => ({
    src: src === "white" ? `${paperForWhite}` : `${paperForGray}`,
  })
)`
  width: 60px;
  height: 60px;
  margin-bottom: 17px;
`;
