import styled from "styled-components";
import colors from "../../styles/colors";

export const BannerContainer = styled.div`
  width: "100%";
  display: flex;
  background-color: ${colors.White};
  align-items: center;
  height: 49px;
`;

export const BannerWrapper = styled.div`
  display: flex;
  width: "100%";
  height: "100%";
  align-items: center;
  position: relative;
`;

export const BannerImage = styled.img`
  object-fit: fill;
`;

export const BannerTextWrapper = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 120px;
  width: calc(100% - 140px);
  height: 49px;
  align-items: center;
  position: absolute;
`;

export const IndexIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 6px;
  height: 17px;
  border-radius: 9px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 20px;
  zindex: 100;
`;
