import React from "react";

import { Container, PaperIcon } from "./styles";
import { ShowTranslation } from "../../components";
import colors from "../../styles/colors";

import getTranslation from "../../common/utils/getTranslation";

interface IProps {
  backgroundType?: "white" | "gray";
  text?: string | string[];
  subtractHeight?: number;
}

const EmptyList = ({
  backgroundType = "white",
  text,
  subtractHeight,
}: IProps) => {
  return (
    <Container backgroundType={backgroundType} subtractHeight={subtractHeight}>
      <PaperIcon src={backgroundType} />
      <ShowTranslation size={12} weight={"r"} color={colors.Gray500}>
        {text ? text : getTranslation("Empty.defaultText")}
      </ShowTranslation>
    </Container>
  );
};

EmptyList.defaultProps = {
  text: "",
};

export default EmptyList;
