import React from "react";

import { ShowTranslation } from "../../components";

import getTranslation from "../../common/utils/getTranslation";

import colors from "../../styles/colors";

import ProfileExchangeIcon from "../../assets/images/ProfileExchange_20_Gray900@3x.png";

import { ProfileExchangeButtonProps } from "./types";
import { Container, Icon } from "./styles";

const ProfileExchangeButton = ({ onClick }: ProfileExchangeButtonProps) => {
  return (
    <Container onClick={onClick}>
      <Icon marginRight={6} src={ProfileExchangeIcon} width={20} height={20} />
      <ShowTranslation size={15} weight={"b"} color={colors.Gray900}>
        {getTranslation("ProfileExchangeButton.buttonText")}
      </ShowTranslation>
    </Container>
  );
};

export default ProfileExchangeButton;
