import React, { MouseEvent } from "react";
import { debounce } from "lodash";

import colors from "../../styles/colors";

import getTranslation from "../../common/utils/getTranslation";

import generateRandomNickname from "../../common/utils/generateRandomNickname";

import { getCreatedAtTimes } from "../../common/utils/timeFormatters";
import ReactNativeWebview from "../../common/utils/ReactNativeWebview";

import { SoundPlayer, FeedHiddenContent, ShowTranslation, BigGif } from "..";

import useAuth from "../../common/hooks/useAuth";
import more from "../../assets/images/More_18_Gray800@3x.png";
import filledHeart from "../../assets/images/icHeartFill@3x.png";
import emptyHeart from "../../assets/images/icHeartEmpty@3x.png";
import managerCheck from "../../assets/images/icVerified@3x.png";
import male from "../../assets/images/img_feedmale@3x.png";
import female from "../../assets/images/img_feedfemale@3x.png";
import nonbinary from "../../assets/images/img_feednonbinary@3x.png";

import {
  FeedRecommentContainer,
  ImageButton,
  CommentContainer,
  ButtonWrapper,
  NicknameWrapper,
  Icon,
  ContentWrapper,
  ActionButtonsWrapper,
  Wrapper,
  EmptyMore,
  IsDeletedWrapper,
  LegacyFeedCallRoomWrapper,
} from "./styles";

import {
  IFeedRecommentItem,
  IFeedCommentItem,
} from "../../common/types/commonTypes";

import useUserControl from "../../common/hooks/useUserControl";

type FileType = "AUDIO" | "GIF" | "NORMAL" | "IMAGE";
type Files = Array<{ url: string }>;

type ReplyToParams = {
  feedId: string;
  commentId: string;
  mentionedUserUuid: string;
  text: string;
  fileType: FileType;
  files: Files;
  audioDuration: number;
};

interface IProps {
  item: IFeedRecommentItem;
  parentItem: IFeedCommentItem;
  onClickReplyToRecomment: ({
    recomment,
    callback,
  }: {
    recomment: IFeedRecommentItem;
    comment: IFeedCommentItem;
    callback: (params: ReplyToParams) => Promise<void>;
  }) => void;
  replyTo: (params: ReplyToParams) => Promise<void>;
  onClickLikeRecomment: (recomment: IFeedRecommentItem) => void;
  onClickUnlikeRecomment: (recomment: IFeedRecommentItem) => void;
  showUserControlModal?: () => void;
}

const FeedRecommentItem = ({
  item,
  parentItem,
  onClickReplyToRecomment,
  replyTo,
  onClickLikeRecomment,
  onClickUnlikeRecomment,
  showUserControlModal,
}: IProps) => {
  const { userData } = useAuth();
  const { user, recomment, is_liked } = item;
  const { getUserControlType } = useUserControl();

  const {
    uuid,
    sex,
    representative_image,
    relation_with_me,
    is_manager,
    is_delete,
    nickname,
    approval,
    country,
  } = user;

  const {
    recomment_id,
    comment_id,
    hide,
    content,
    total_count_likes,
    total_count_reports,
    total_count_comments,
    created_at,
    feedpost_id,
    mentioned_user,
    is_delete: recomment_is_delete,
  } = recomment;

  const {
    uuid: mentioned_user_uuid,
    nickname: mentioned_user_nickname,
    is_manager: mentioned_user_is_manager,
  } = mentioned_user;

  const { text, url, type, duration } = content;

  const onClickMore = (event: MouseEvent<HTMLButtonElement>) => {
    if (getUserControlType() === "PAUSE") {
      // TODO: 유저컨트롤 모달 전역 상태 로 관리 할수있게 refactoring 해야함
      showUserControlModal && showUserControlModal();
      event.stopPropagation();
      return;
    }

    ReactNativeWebview.postMessageToApp({
      type: "SHOW_ACTION_SHEET",
      data: {
        contentUserCountry: country,
        contentUserApprovalStatus: approval,
        contentUserUuid: uuid,
        feedId: feedpost_id,
        recommentId: recomment_id,
        commentId: comment_id,
        blockPostType: "RECOMMENT",
        postType: "COMMENT",
        contentId: recomment_id,
      },
    });

    event.stopPropagation();
  };

  const onClickuserInfo = (
    event: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLDivElement>
  ) => {
    if (relation_with_me === "ME" || is_manager) return;

    ReactNativeWebview.postMessageToApp({
      type: "SHOW_PROFILE_EXCHANGE_REQUEST_MODAL",
      data: {
        userUuid: uuid,
        userNickname: nickname,
        userSex: sex,
        feedId: feedpost_id,
        recommentId: recomment_id,
        contentType: content.type,
        contentText: content.text,
        approvalStatus: approval,
      },
    });

    event.stopPropagation();
  };

  const onClickReply = () => {
    onClickReplyToRecomment({
      recomment: item,
      comment: parentItem,
      callback: replyTo,
    });
  };

  const onClickHeart = debounce((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (is_liked) {
      onClickUnlikeRecomment(item);
      return;
    }
    onClickLikeRecomment(item);
  }, 300);

  const renderUserIcon = () => {
    const userImage = is_manager
      ? representative_image
      : sex === "F"
      ? female
      : sex === "O"
      ? nonbinary
      : male;
    return (
      <ButtonWrapper>
        <ImageButton
          style={{ borderRadius: 28 / 2 }}
          disabled={relation_with_me === "ME" || is_manager || hide !== "NONE"}
          source={userImage}
          width={28}
          height={28}
          onClick={onClickuserInfo}
        />
      </ButtonWrapper>
    );
  };

  const renderContent = () => {
    const renderSoundPlayer = () => {
      if (type === "AUDIO" && url.length > 0) {
        return (
          <ContentWrapper>
            <SoundPlayer
              type={"MINI"}
              audioUrl={url[0].url!}
              initDuration={duration}
            />
          </ContentWrapper>
        );
      }
    };

    const renderGif = () => {
      if (type === "GIF" && url.length > 0) {
        return (
          <ContentWrapper>
            <BigGif source={url[0].url!} />
          </ContentWrapper>
        );
      }
    };

    const renderLegacyCallRoom = () => {
      if (type === "CALL_ROOM") {
        return (
          <LegacyFeedCallRoomWrapper>
            <ShowTranslation
              style={{ flexShrink: 1 }}
              size={13}
              color={colors.Gray500}
              weight={"r"}
            >
              {getTranslation("Common.legacyFeature")}
            </ShowTranslation>
          </LegacyFeedCallRoomWrapper>
        );
      }
    };

    const renderHiddenContent = () => {
      if (hide === "CS") {
        return (
          <FeedHiddenContent
            style={{ marginTop: 6, marginLeft: 0, marginRight: 0 }}
            hiddenType={hide}
          />
        );
      }

      if (hide === "REPORT") {
        return (
          <FeedHiddenContent
            style={{ marginTop: 6, marginLeft: 0, marginRight: 0 }}
            hiddenType={hide}
            reportCount={total_count_reports}
          />
        );
      }
    };

    const renderText = () => {
      if (text.length > 0) {
        return (
          <ContentWrapper>
            <ShowTranslation
              style={{
                display: "inline",
                marginBottom: 6,
                lineHeight: "20px",
                lineBreak: "anywhere",
              }}
              size={15}
              weight={"m"}
              color={colors.linkBlue}
            >
              {`@${generateRandomNickname(
                mentioned_user_nickname,
                feedpost_id
              )} `}
            </ShowTranslation>
            <ShowTranslation
              style={{
                display: "inline",
                marginBottom: 6,
                lineHeight: "20px",
                lineBreak: "anywhere",
              }}
              size={15}
              weight={"r"}
              color={colors.Gray900}
              highlightWeight={"m"}
            >
              {text}
            </ShowTranslation>
          </ContentWrapper>
        );
      }
    };

    return (
      <CommentContainer>
        <NicknameWrapper onClick={onClickuserInfo}>
          <ShowTranslation size={14} weight={"m"} color={colors.Gray900}>
            {is_manager
              ? nickname
              : generateRandomNickname(nickname, feedpost_id)}
          </ShowTranslation>

          {is_manager && (
            <Icon
              style={{ marginLeft: 3 }}
              width={12}
              height={12}
              src={managerCheck}
            />
          )}

          {relation_with_me === "ME" && (
            <ShowTranslation
              style={{
                paddingRight: 6,
                paddingLeft: 6,
                height: 16,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: colors.Blue700,
                borderRadius: 8,
                marginLeft: 1,
              }}
              size={11}
              weight={"b"}
              color={colors.White}
            >
              {getTranslation("FeedCommon.myComment")}
            </ShowTranslation>
          )}
        </NicknameWrapper>
        {hide === "NONE" || (hide === "OWN" && uuid === userData.uuid) ? (
          <>
            {renderText()}
            {renderSoundPlayer()}
            {renderGif()}
            {renderLegacyCallRoom()}
          </>
        ) : (
          renderHiddenContent()
        )}
      </CommentContainer>
    );
  };

  const renderMoreButton = () => {
    if (hide !== "NONE" || is_manager || relation_with_me === "ME") {
      return <EmptyMore />;
    }

    return (
      <ButtonWrapper>
        <ImageButton
          source={more}
          width={18}
          height={18}
          onClick={onClickMore}
        />
      </ButtonWrapper>
    );
  };

  const renderCommentActionButtons = () => {
    if (hide === "NONE") {
      return (
        <ActionButtonsWrapper>
          <ShowTranslation
            style={{ marginRight: 12 }}
            size={12}
            weight={"r"}
            color={colors.Gray600}
          >
            {getCreatedAtTimes(created_at)}
          </ShowTranslation>

          <ImageButton
            width={16}
            height={16}
            marginRight={4}
            source={is_liked ? filledHeart : emptyHeart}
            onClick={onClickHeart}
          />

          <ShowTranslation
            style={{ marginRight: 12 }}
            size={13}
            weight={"r"}
            color={colors.Gray600}
          >
            {`${total_count_likes}`}
          </ShowTranslation>

          <ButtonWrapper onClick={onClickReply}>
            <ShowTranslation size={12} weight={"m"} color={colors.Gray600}>
              {getTranslation("FeedCommon.replyToComment")}
            </ShowTranslation>
          </ButtonWrapper>
        </ActionButtonsWrapper>
      );
    }
  };

  const renderIsDeletedUser = () => {
    return (
      <IsDeletedWrapper>
        <ShowTranslation
          weight={"m"}
          size={14}
          color={colors.Gray900}
          style={{ marginBottom: "6px" }}
        >
          {getTranslation("FeedCommon.isDeletedUser")}
        </ShowTranslation>
        <ShowTranslation weight={"r"} size={13} color={colors.Gray500}>
          {getCreatedAtTimes(created_at)}
        </ShowTranslation>
      </IsDeletedWrapper>
    );
  };

  const renderIsDeletedComment = () => {
    return (
      <IsDeletedWrapper>
        <ShowTranslation
          weight={"m"}
          size={14}
          color={colors.Gray900}
          style={{ marginBottom: "6px" }}
        >
          {getTranslation("FeedCommon.isDeletedComment")}
        </ShowTranslation>
        <ShowTranslation weight={"r"} size={13} color={colors.Gray500}>
          {getCreatedAtTimes(created_at)}
        </ShowTranslation>
      </IsDeletedWrapper>
    );
  };

  if (hide === "OWN" && uuid !== userData.uuid) {
    return null;
  }

  return (
    <FeedRecommentContainer>
      <Wrapper>
        {is_delete && renderIsDeletedUser()}
        {!is_delete && recomment_is_delete && renderIsDeletedComment()}
        {!is_delete && !recomment_is_delete && (
          <>
            {renderUserIcon()}
            {renderContent()}
            {renderMoreButton()}
          </>
        )}
      </Wrapper>
      {!is_delete && !recomment_is_delete && renderCommentActionButtons()}
    </FeedRecommentContainer>
  );
};

export default FeedRecommentItem;
