import styled from "styled-components";
import colors from "../../styles/colors";
import TextAreaAutosize from "react-textarea-autosize";
import fontWeight from "../../styles/fontWeights";

import gifWatermark from "../../assets/images/GiphyMark_18@3x.png";

import {
  ImageSourceButton,
  ImageSourceButtonProps,
} from "../../styles/commonStyles";

interface ImageButtonProps extends ImageSourceButtonProps {
  marginRight?: number;
  marginLeft?: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0px;
  background-color: ${colors.White};
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 8px;
  padding-left: 14px;
  padding-right: 14px;
  border-top: 1px solid ${colors.Gray300};
`;

export const InputUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 8px;
`;

export const TextInputContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-end;
  background-color: ${colors.Gray200};
  border-radius: 20px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  flex-grow: 1;
  margin-right: 8px;
  overflow-y: scroll;
`;

export const GifAudioButtonContainer = styled.div`
  display: flex;
  position: absolute;
  right: 18px;
  flex-direction: row;
  align-items: center;
  gap: 14px;
`;

export const TextInput = styled(TextAreaAutosize)`
  border: none;
  background-color: ${colors.Gray200};
  outline: none;
  resize: none;
  width: 100%;
  min-height: 20px;
  max-height: 160px;
  font-size: 15px;
  font-weight: ${fontWeight.regular};
  color: ${colors.Gray900};
  line-height: 20px;
  overflow-y: scroll;
  padding: 0px;
  padding-right: 120px;
  font-family: inherit;

  ::placeholder {
    font-family: inherit;
    font-size: 15px;
    padding: 0px;
    font-weight: ${fontWeight.regular};
    color: ${colors.Gray500};
  }
`;

export const ImageButton = styled(ImageSourceButton)`
  margin-right: ${(props: ImageButtonProps) => props.marginRight || 0}px;
  margin-left: ${(props: ImageButtonProps) => props.marginLeft || 0}px;
`;

export const GifWatermark = styled.img.attrs({
  src: gifWatermark,
})`
  display: flex;
  width: 119px;
  height: 18px;
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 8px;
`;

export const GifWaterMarkCloseContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 6px;
`;

export const Gif = styled.img`
  border-radius: 6px;
  width: 80px;
  height: 80px;
  object-fit: cover;
  aspect-ratio: 1;
`;

export const MentionedUserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
`;
