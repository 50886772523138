import React, { useState, useEffect, useRef } from "react";
import { ModalProvider } from "styled-react-modal";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import useInput from "../../common/hooks/useInput";

import useScrollRef from "../../common/hooks/useScrollRef";
import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";
import { unwrapResult } from "@reduxjs/toolkit";
import useModal from "../../common/hooks/useModal";
import useUserControl from "../../common/hooks/useUserControl";
import { FreeProfileExchangeItemUseNudgeToolTip, UserControlModal } from "..";

import {
  CommonHeader,
  FeedItem,
  CommentInput,
  Loading as LoadingScreen,
  LoadingMoreIndicator,
  FeedCommentItem,
  Error,
  PullToRefresh,
  ShowTranslation,
} from "../../components";

import { parseNextPageUrl } from "../../common/utils/parseNextPageUrl";
import getTranslation from "../../common/utils/getTranslation";
import {
  ModalBackground,
  StyledModal,
  StickyHeader,
  Container,
  ProfileExchangeIcon,
  FeedItemContainer,
} from "./styles";

import {
  selectToolTips,
  setToolTips,
} from "../../features/globalAppState/globalAppStateSlice";

import { likeFeed, unlikeFeed } from "../../features/feedList/feedListThunks";

import colors from "../../styles/colors";

import {
  fetchFeedDetail,
  createFeedComment,
  fetchFeedComments,
  likeComment,
  unlikeComment,
} from "../../features/feedDetail/feedDetailThunks";

import {
  IFeedCommentItem,
  IFeedRecommentItem,
  IFeedItem,
} from "../../common/types/commonTypes";

import { MessageFromAppCallbacks } from "../../common/utils/ReactNativeWebview/appEventListenerService/types";

import ReactNativeWebview from "../../common/utils/ReactNativeWebview";

import { ProfileExchangeToolTip } from "../../systems";
import {
  createBookmark,
  deleteBookmark,
} from "../../features/bookmark/bookmarkThunks";

type FileType = "AUDIO" | "GIF" | "NORMAL" | "IMAGE";
type Files = Array<{ url: string }>;
type Loading = "LOADING" | "LOADING_MORE" | "NONE" | "UPLOADING";
type ErrorType = "DELETE" | "GET_FEED_DETAIL" | "GET_FEED_COMMENT" | "NONE";

type ReplyToParams = {
  feedId: string;
  commentId: string;
  mentionedUserUuid: string;
  text: string;
  fileType: FileType;
  files: Files;
  audioDuration: number;
  callRoomId?: string;
};

const inputValidator = (text: string) => {
  if (text.trim().length >= 1) {
    return true;
  }
  return false;
};

const FeedDetailModal = () => {
  const [modalRef, setModalRef] = useState<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id: feedId } = useParams();
  const location = useLocation();
  const { state }: { state: any } = location;

  const { isReachedBottom } = useScrollRef(modalRef);

  const { getUserControlType } = useUserControl();

  const {
    isVisible: isUserControlModalVisible,
    showModal: showUserControlModal,
    closeModal: closeUserControlModal,
  } = useModal();

  const {
    value: text,
    onChangeText,
    isValidInput,
    resetValue: resetText,
  } = useInput({
    initialValue: "",
    validator: inputValidator,
  });

  const freeProfileExchangeItemUseNudgeToolTipStatus =
    useAppSelector(selectToolTips).FREE_PROFILE_EXCHANGE_ITEM_USE_NUDGE_VISIBLE;

  const [feedItem, setFeedItem] = useState<IFeedItem | null>(null);
  const [comments, setComments] = useState<Array<IFeedCommentItem>>([]);
  const [commentsNextPageUrl, setCommentsNextPageUrl] = useState("");
  const [files, setFiles] = useState<Files>([]);
  const [fileType, setFileType] = useState<FileType>("NORMAL");
  const [audioDuration, setAudioDuration] = useState(0);
  const [loading, setLoading] = useState<Loading>("LOADING");
  const [error, setError] = useState<ErrorType>("NONE");
  const [isDeletedUser, setIsDeletedUser] = useState(false);
  const [isDeletedFeed, setIsDeletedFeed] = useState(false);
  const [isBlockedFeed, setIsBlockedFeed] = useState(false);
  const [isReportedFeed, setIsReportedFeed] = useState(false);
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [isUploadButtonDisabled, setIsUploadButtonDisabled] = useState(true);

  const [attachCallRoom, setAttachCallRoom] = useState<any>();

  const [selectedCommentToReply, setSelectedCommentToReply] = useState<{
    comment: IFeedCommentItem;
    callback: (params: ReplyToParams) => Promise<void>;
  } | null>(null);

  const [selectedRecommentToReply, setSelectedRecommentToReply] = useState<{
    recomment: IFeedRecommentItem;
    comment: IFeedCommentItem;
    callback: (params: ReplyToParams) => Promise<void>;
  } | null>(null);

  const updateList = (
    commentList: Array<IFeedCommentItem>,
    commentItem: IFeedCommentItem,
    actionType: "LIKE" | "UNLIKE" | "COMMENT"
  ) => {
    return commentList.map((item) => {
      if (item.comment.comment_id === commentItem.comment.comment_id) {
        const updatedComment = { ...commentItem };
        const { comment } = updatedComment;
        const updatedCommentPost = { ...comment };

        if (actionType === "LIKE") {
          updatedComment.is_liked = true;
          updatedCommentPost.total_count_likes += 1;
        }

        if (actionType === "UNLIKE") {
          updatedComment.is_liked = false;
          updatedCommentPost.total_count_likes -= 1;
        }

        if (actionType === "COMMENT") {
          updatedCommentPost.total_count_comments += 1;
        }

        updatedComment.comment = updatedCommentPost;
        return updatedComment;
      }
      return item;
    });
  };

  const updateFeedItem = (
    feedItem: IFeedItem,
    actionType:
      | "LIKE"
      | "UNLIKE"
      | "COMMENT"
      | "CREATE_BOOKMARK"
      | "DELETE_BOOKMARK"
  ) => {
    const updatedFeed = { ...feedItem };
    const { feedpost } = updatedFeed;
    const updatedPost = { ...feedpost };

    if (actionType === "LIKE") {
      updatedFeed.is_liked = true;
      updatedPost.total_count_likes += 1;
    }

    if (actionType === "UNLIKE") {
      updatedFeed.is_liked = false;
      updatedPost.total_count_likes -= 1;
    }

    if (actionType === "COMMENT") {
      updatedPost.total_count_comments += 1;
    }

    if (actionType === "CREATE_BOOKMARK") {
      updatedFeed.is_bookmarked = true;
    }

    if (actionType === "DELETE_BOOKMARK") {
      updatedFeed.is_bookmarked = false;
    }

    updatedFeed.feedpost = updatedPost;

    return updatedFeed;
  };

  const getFeedDetail = async () => {
    try {
      if (feedId) {
        const feedDetailResult = await dispatch(
          fetchFeedDetail({ feedId: feedId })
        );

        const unwrappedFeedDetailResult = unwrapResult(feedDetailResult);

        if (
          !(
            unwrappedFeedDetailResult.feedpost.hide === "OWN" ||
            unwrappedFeedDetailResult.feedpost.hide === "NONE"
          )
        ) {
          setIsReportedFeed(true);
          return;
        }

        setFeedItem(unwrappedFeedDetailResult);
      }
    } catch (error: any) {
      if (error.res_code === "DELETED_USER_FEED_POST") {
        setIsDeletedUser(true);
        setLoading("NONE");
      } else if (error.res_code === "FEED_POST_DOES_NOT_EXIST") {
        setIsDeletedFeed(true);
        setLoading("NONE");
      } else if (error.res_code === "BLOCKED_USER") {
        setIsBlockedFeed(true);
        setLoading("NONE");
      } else {
        setError("GET_FEED_DETAIL");
        setLoading("NONE");
      }
    }
  };

  const getFeedComments = async (fetchType: "INIT" | "MORE") => {
    try {
      if (feedId) {
        if (
          fetchType === "MORE" &&
          parseNextPageUrl(commentsNextPageUrl) === 0
        ) {
          setLoading("NONE");
          return;
        }
        if (fetchType === "MORE") {
          setLoading("LOADING_MORE");
        }

        const feedCommentsResult = await dispatch(
          fetchFeedComments({
            feedId,
            page:
              fetchType === "INIT" ? 0 : parseNextPageUrl(commentsNextPageUrl),
          })
        );
        const unwrappedFeedCommentsResult = unwrapResult(feedCommentsResult);
        if (fetchType === "MORE") {
          setComments((prevComments) =>
            prevComments.concat(unwrappedFeedCommentsResult.comments)
          );
        } else {
          setComments(unwrappedFeedCommentsResult.comments);
        }
        setCommentsNextPageUrl(unwrappedFeedCommentsResult.next_page);

        if (fetchType === "MORE") {
          setLoading("NONE");
        }
      }
    } catch (error) {
      setError("GET_FEED_COMMENT");
      setLoading("NONE");
    }
  };

  const writeFeedComment = async () => {
    if (feedItem) {
      try {
        const writeFeedCommentResult = await dispatch(
          createFeedComment({
            feedId: feedItem.feedpost.feedpost_id,
            text,
            fileType,
            files,
            audioDuration,
          })
        );

        const unwrappedWriteFeedCommentResult = new Array(
          unwrapResult(writeFeedCommentResult)
        );

        setComments((prevComments) =>
          unwrappedWriteFeedCommentResult.concat(prevComments)
        );

        setFeedItem((prevFeedItem) =>
          updateFeedItem(prevFeedItem as IFeedItem, "COMMENT")
        );
        setFileType("NORMAL");
        setAttachCallRoom(null);
        return;
      } catch (error) {
        if (error === "BLACK_TYPE_USER") {
          ReactNativeWebview.postMessageToApp({
            type: "SHOW_ACCOUNT_SUSPENDED_MODAL",
          });
          return;
        }

        if (error === "WRITE_COMMENT_IN_DELETED_FEED_ERROR") {
          ReactNativeWebview.postMessageToApp({
            type: "WRITE_COMMENT_IN_DELETED_FEED_ERROR",
          });
        }
        onClickBack();
        return;
      }
    }
  };

  const focusInput = () => {
    inputRef.current && inputRef.current.focus();
  };

  const onClickBack = () => {
    if (state && "prevpath" in state) {
      navigate(-1);
      return;
    }
    ReactNativeWebview.postMessageToApp({ type: "CLOSE_WEBVIEW" });
  };

  const onClickWrite = async () => {
    if (loading === "UPLOADING") {
      return;
    }

    setLoading("UPLOADING");
    ReactNativeWebview.postMessageToApp({
      type: "SHOW_LOADING_INDICATOR",
      data: {
        isLoading: true,
      },
    });

    if (selectedCommentToReply) {
      const { comment, user } = selectedCommentToReply.comment;

      await selectedCommentToReply.callback({
        feedId: comment.feedpost_id,
        commentId: comment.comment_id,
        mentionedUserUuid: user.uuid,
        text,
        fileType,
        files,
        audioDuration,
      });

      setComments((prevComments) =>
        updateList(prevComments, selectedCommentToReply.comment, "COMMENT")
      );
      setFeedItem((prevFeedItem) =>
        updateFeedItem(prevFeedItem as IFeedItem, "COMMENT")
      );
      resetInputData();
      setLoading("NONE");
      if (feedItem) {
        ReactNativeWebview.postMessageToApp({
          type: "LOG_WROTE_RE_REPLY",
          data: {
            content: fileType,
          },
        });
      }
      ReactNativeWebview.postMessageToApp({
        type: "SHOW_LOADING_INDICATOR",
        data: {
          isLoading: false,
        },
      });

      return;
    }

    if (selectedRecommentToReply) {
      const { recomment, user } = selectedRecommentToReply.recomment;

      await selectedRecommentToReply.callback({
        feedId: recomment.feedpost_id,
        commentId: recomment.comment_id,
        mentionedUserUuid: user.uuid,
        text,
        fileType,
        files,
        audioDuration,
      });

      setComments((prevComments) =>
        updateList(prevComments, selectedRecommentToReply.comment, "COMMENT")
      );

      setFeedItem((prevFeedItem) =>
        updateFeedItem(prevFeedItem as IFeedItem, "COMMENT")
      );
      if (feedItem) {
        ReactNativeWebview.postMessageToApp({
          type: "LOG_WROTE_RE_REPLY",
          data: {
            content: fileType,
          },
        });
      }

      resetInputData();
      setLoading("NONE");
      ReactNativeWebview.postMessageToApp({
        type: "SHOW_LOADING_INDICATOR",
        data: {
          isLoading: false,
        },
      });

      return;
    }

    if (feedItem) {
      ReactNativeWebview.postMessageToApp({
        type: "LOG_WROTE_REPLY",
        data: {
          content: fileType,
        },
      });
    }

    await writeFeedComment();
    resetInputData();
    setLoading("NONE");
    ReactNativeWebview.postMessageToApp({
      type: "SHOW_LOADING_INDICATOR",
      data: {
        isLoading: false,
      },
    });
    return;
  };

  const onClickWriteButton = () => {
    if (
      getUserControlType() === "PAUSE" ||
      getUserControlType() === "WARNING" ||
      getUserControlType() === "FIRST_WARNING"
    ) {
      showUserControlModal();
      return;
    }
    onClickWrite();
  };

  const onCloseUserControlModal = () => {
    if (getUserControlType() === "PAUSE") {
      closeUserControlModal();
      return;
    }
    closeUserControlModal();
    onClickWrite();
  };

  const onClickComment = () => {
    focusInput();
    setSelectedCommentToReply(null);
    setSelectedRecommentToReply(null);
  };

  const onClickUpload = (fileType: FileType) => {
    setFileType(fileType);
    ReactNativeWebview.postMessageToApp({
      type: "REQUEST_FILE_UPLOAD",
      data: { fileType },
    });

    return;
  };

  const onClickRemoveFile = () => {
    setAudioDuration(0);
    setFiles([]);
    setFileType("NORMAL");
    setAttachCallRoom(null);
  };

  const onClickRemoveMentioningUser = () => {
    setSelectedCommentToReply(null);
    setSelectedRecommentToReply(null);
  };

  const onClickReplyToComment = (replyCommentParams: {
    comment: IFeedCommentItem;
    callback: (params: ReplyToParams) => Promise<void>;
  }) => {
    focusInput();
    setSelectedRecommentToReply(null);
    setSelectedCommentToReply(replyCommentParams);
  };

  const onClickReplyToRecomment = (replyRecommentParams: {
    recomment: IFeedRecommentItem;
    comment: IFeedCommentItem;
    callback: (params: ReplyToParams) => Promise<void>;
  }) => {
    focusInput();
    setSelectedCommentToReply(null);
    setSelectedRecommentToReply(replyRecommentParams);
  };

  const onClickLikeFeed = () => {
    if (feedItem) {
      dispatch(likeFeed({ feedId: feedItem.feedpost.feedpost_id }));
      setFeedItem((prevFeedItem) => {
        return updateFeedItem(prevFeedItem as IFeedItem, "LIKE");
      });
      ReactNativeWebview.postMessageToApp({
        type: "LOG_SENT_FEED_LIKES",
      });
    }
  };

  const onClickUnlikeFeed = () => {
    if (feedItem) {
      dispatch(unlikeFeed({ feedId: feedItem.feedpost.feedpost_id }));
      setFeedItem((prevFeedItem) =>
        updateFeedItem(prevFeedItem as IFeedItem, "UNLIKE")
      );
    }
  };

  const onClickProfileExchangeIcon = () => {
    ReactNativeWebview.postMessageToApp({
      type: "NAVIGATE_TO_PROFILE_EXCHANGE",
    });
  };

  const onClickLikeComment = (comment: IFeedCommentItem) => {
    dispatch(
      likeComment({
        feedId: comment.comment.feedpost_id,
        commentId: comment.comment.comment_id,
      })
    );
    setComments((prevComments) => updateList(prevComments, comment, "LIKE"));
    ReactNativeWebview.postMessageToApp({
      type: "LOG_SENT_FEED_LIKES",
    });
  };

  const onClickUnlikeComment = (comment: IFeedCommentItem) => {
    dispatch(
      unlikeComment({
        feedId: comment.comment.feedpost_id,
        commentId: comment.comment.comment_id,
      })
    );

    setComments((prevComments) => updateList(prevComments, comment, "UNLIKE"));
  };

  const onClickCreateBookmark = async () => {
    if (feedItem) {
      feedId &&
        (await dispatch(
          createBookmark({
            feedpostId: feedId,
            contentType: fileType,
            contentUserUuid: feedItem.user.uuid,
          })
        ));
    }

    setFeedItem((prevFeedItem) =>
      updateFeedItem(prevFeedItem as IFeedItem, "CREATE_BOOKMARK")
    );
  };

  const onClickDeleteBookmark = async () => {
    if (feedItem) {
      feedId && (await dispatch(deleteBookmark({ feedpostId: feedId })));
    }

    setFeedItem((prevFeedItem) =>
      updateFeedItem(prevFeedItem as IFeedItem, "DELETE_BOOKMARK")
    );
  };

  const resetInputData = () => {
    resetText();
    setSelectedCommentToReply(null);
    setSelectedRecommentToReply(null);
    setFiles([]);
    setFileType("NORMAL");
    setAttachCallRoom(null);
    setAudioDuration(0);
  };

  const fetchFeedAndComments = async () => {
    setRefreshCount((prev) => prev + 1);
    await Promise.all([getFeedDetail(), getFeedComments("INIT")]);
    setLoading("NONE");
  };

  useEffect(() => {
    fetchFeedAndComments();
    ReactNativeWebview.postMessageToApp({
      type: "PROFILE_EXCHANGE_TOOL_TIP_SEEN",
    });

    return () => {
      if (freeProfileExchangeItemUseNudgeToolTipStatus) {
        dispatch(
          setToolTips({
            key: "FREE_PROFILE_EXCHANGE_ITEM_USE_NUDGE_VISIBLE",
            isVisible: false,
          })
        );
      }

      dispatch(
        setToolTips({ key: "PROFILE_EXCHANGE_VISIBLE", isVisible: false })
      );
    };
  }, [freeProfileExchangeItemUseNudgeToolTipStatus]);

  useEffect(() => {
    if (error === "DELETE") {
      setError("NONE");
    }
  }, [error]);

  useEffect(() => {
    if (loading === "UPLOADING") {
      setIsUploadButtonDisabled(true);
      return;
    }
    setIsUploadButtonDisabled(!(files.length > 0 || isValidInput));
  }, [files, fileType, isValidInput, loading]);

  useEffect(() => {
    if (isReachedBottom && loading === "NONE") {
      getFeedComments("MORE");
    }
  }, [isReachedBottom, loading]);

  useEffect(() => {
    const handleDeliveredFiles: MessageFromAppCallbacks["DELIVER_UPLOADED_FILES"] =
      (data: any) => {
        if (fileType === "GIF") {
          setFiles([{ url: data.files[0].url }]);
          return;
        }

        if (fileType === "AUDIO") {
          setFiles([{ url: data.files[0].url }]);
          setAudioDuration(data.files[0].duration ? data.files[0].duration : 0);
          return;
        }
      };

    ReactNativeWebview.addAppEventListener(
      "DELIVER_UPLOADED_FILES",
      handleDeliveredFiles
    );

    return () => {
      ReactNativeWebview.removeAppEventListener(
        "DELIVER_UPLOADED_FILES",
        handleDeliveredFiles
      );
    };
  }, [fileType]);

  const renderFeedComments = () => {
    if (comments.length > 0) {
      return comments.map((item: IFeedCommentItem, index) => (
        <FeedCommentItem
          showUserControlModal={showUserControlModal}
          refreshCount={refreshCount}
          key={`feed-detail-comment-id-${item.comment.comment_id}`}
          item={item}
          onClickReplyToComment={onClickReplyToComment}
          onClickReplyToRecomment={onClickReplyToRecomment}
          onClickLikeComment={onClickLikeComment}
          onClickUnlikeComment={onClickUnlikeComment}
        />
      ));
    }
    return null;
  };

  const renderContent = () => {
    if (isDeletedUser) {
      return (
        <Error
          text={getTranslation("FeedCommon.isDeletedUserPost") as string}
        />
      );
    }

    if (isDeletedFeed) {
      return (
        <Error text={getTranslation("FeedDetailModal.isDeleted") as string} />
      );
    }

    if (isReportedFeed) {
      return (
        <Error text={getTranslation("FeedDetailModal.isReported") as string} />
      );
    }

    if (isBlockedFeed) {
      return (
        <Error text={getTranslation("FeedDetailModal.isBlocked") as string} />
      );
    }

    if (loading === "LOADING") {
      return <LoadingScreen />;
    }

    return (
      <div>
        <PullToRefresh onRefresh={fetchFeedAndComments}>
          <FeedItemContainer>
            <ProfileExchangeToolTip />
            <FreeProfileExchangeItemUseNudgeToolTip />
            {feedItem && (
              <FeedItem
                showUserControlModal={showUserControlModal}
                onClickComment={onClickComment}
                item={feedItem as IFeedItem}
                likeFeedFromDetail={onClickLikeFeed}
                unlikeFeedFromDetail={onClickUnlikeFeed}
                createBookmarkDetail={onClickCreateBookmark}
                deleteBookmarkDetail={onClickDeleteBookmark}
                isDetail={true}
              />
            )}

            {renderFeedComments()}
          </FeedItemContainer>
        </PullToRefresh>
      </div>
    );
  };

  const renderCommentInput = () => {
    if (
      loading !== "LOADING" &&
      !isBlockedFeed &&
      !isDeletedFeed &&
      !isReportedFeed &&
      !isDeletedUser
    ) {
      return (
        <CommentInput
          attachCallRoom={attachCallRoom}
          ref={inputRef}
          files={files}
          audioDuration={audioDuration}
          fileType={fileType}
          text={text}
          selectedCommentToReply={
            selectedCommentToReply ? selectedCommentToReply.comment : null
          }
          selectedRecommentToReply={
            selectedRecommentToReply ? selectedRecommentToReply.recomment : null
          }
          isUploadButtonDisabled={isUploadButtonDisabled}
          onClickRemoveFile={onClickRemoveFile}
          onClickUpload={onClickUpload}
          onClickWrite={onClickWriteButton}
          onChangeText={onChangeText}
          onClickRemoveMentioningUser={onClickRemoveMentioningUser}
        />
      );
    }
  };

  return (
    <>
      <UserControlModal
        isVisible={isUserControlModalVisible}
        onClose={onCloseUserControlModal}
      />
      <ModalProvider backgroundComponent={ModalBackground}>
        <StickyHeader>
          <CommonHeader
            headerLeft={"back"}
            headerTitle={
              <ShowTranslation size={17} weight={"m"} color={colors.Gray900}>
                {getTranslation("FeedDetailModal.headerTitle")}
              </ShowTranslation>
            }
            onClickLeft={onClickBack}
            headerRight={<ProfileExchangeIcon />}
            onClickRight={onClickProfileExchangeIcon}
          />
        </StickyHeader>
        <StyledModal isOpen={true}>
          <Container ref={(ref) => setModalRef(ref)}>
            {renderContent()}
            <LoadingMoreIndicator isLoadingMore={loading === "LOADING_MORE"} />
          </Container>

          {renderCommentInput()}
        </StyledModal>
      </ModalProvider>
    </>
  );
};

export default FeedDetailModal;
